<template>
  <div v-if="isSkeletonLoading" class="table-skeleton-preloading">
    <Skeletor
      v-for="n in INITIAL_ROWS_COUNT"
      :key="n"
      class="table-skeleton-preloading__table-row-base" as="div" />
  </div>
</template>

<script setup lang="ts">

  import { Skeletor } from 'vue-skeletor';

  defineProps<{
    isSkeletonLoading: boolean,
  }>();

  const INITIAL_ROWS_COUNT = 8;
</script>

<style src="~/assets/styles/components/table/skeleton-preloader.scss" lang="scss" />
